@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  .loading-div{
    position: fixed;
    z-index: 999999999999;
    top: 0;
    left: 0;
    background: #ffffffbf;
    width: 100%;
    height: 100%;
  }
  .loading-component{
    width: 5rem;
    height: 5rem;
    /* vertical-align: -25.125em; */
    border: 0.25em solid currentcolor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
    /* right: 25em; */
    /* width: 19%; */
    /* margin: auto; */
    /* text-align: center; */
    display: block;
    margin: auto;
    position: relative;
    top: 50%;
  }