html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.cursor-pointer{
  cursor: pointer;
}
.riga-trova-impresa{
  margin-bottom: 15px; 
  padding: 15px 15px 15px 15px; 
  background-color: #e3e6f0;
}
.icon-azienda-trova-impresa{
  font-size: 2rem;
  margin: auto;
  display: block;
  text-align: center;
}
.component-maxwidth{
  width: 100%;
}
.heigth-4{
  height: 4em;
}
.boldtesto{
  font-weight: bold;
}
.textcenterModalElenco{
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #e3e6f0;
  margin-top: 15px;
  margin-bottom: 15px;
}
.heigthRigaElenco{
  height: 30px;
}
.icon-size-normal{
  font-size: 2rem;
}
.icon-size-big{
  font-size: 3rem;
}
.icon-size-small{
  font-size: 1.2rem;
}
.font-size-1{
  font-size: 1.0rem;
}
.color-icon-notfound{
  color: #db2723;
}
.color-icon-green{
  color: #008000b0;
}
.color-icon-orange{
  color: #FF8300;
}
.color-icon-yellow{
  color: #f8f32b;
}
.color-icon-red{
  color: #db2723;
}
.color-icon-default{
  color: #4e73df;
}
.color-icon-gray{
  color: #858796;
}
.font-size-1-2-bold{
  font-size: 1.2em;
  font-weight: bold;
}
.font-size-1-2{
  font-size: 1.2em;
}
.font-size-1-5{
  font-size: 1.5em!important;
}
.padding-bottom20{
  padding-bottom: 20px;
}
.no-padding{
  padding: 0px;
}

.fieldset {
  overflow: hidden
}

.some-class {
  float: left;
  clear: none;
}

label {
  float: left;
  clear: none;
  display: block;
  padding: 0px 1em 0px 8px;
}

/* The container */
.containerradio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

  /* Hide the browser's default radio button */
  .containerradio input[type=radio] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
  }

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 25px;
  background-color: #eee;
  border-radius: 0%;
}

/* On mouse-over, add a grey background color */
.containerradio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerradio input:checked ~ .checkmark {
  background-color: #db2723;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerradio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerradio .checkmark:after {
  top: 42%;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 0%;
  background: white;
}

.ampiezzamax {
  width: 100%;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  // color: #fff!important;
  // background-color: #3d63d2!important;
  // border-color: #3d63d2 #3d63d2 #fff!important;
  color: #008000b0 !important;
  font-weight: bold;
  background-color: transparent!important;
  border-bottom-color: #008000b0 !important;
  border-bottom: 2px solid!important;
  border-top: none;
  border-left: none;
  border-right: none;

}
.border-right-5{
  border-right: 2px solid;
}
.decoration-none{
  text-decoration: none!important;;
}
.padding-zero{
  padding: 0px!important;
}
.border-left-8{
  border-left: 8px solid;
}
.border-left-8-green{
  border-left: 8px solid #008000b0;
  border-radius: 15px;
}
.border-left-8-red{
  border-left: 8px solid #db2723;
  border-radius: 15px;
}
.center-block-custom{
  display: block;
  margin: auto;
}

// .main-app {
//   margin: 2% 10%;
//   border: 1px ridge #464141;
//   padding: 5%;
//   font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
//   font-size: 20px;
//   color: #464141;
//  }
  
 .container {
  // border-bottom: #fff 2px ridge;
 }
  
 .pagination {
  margin-top: 45px;
  //margin-left: -40px;
  display: flex;
  list-style: none;
  outline: none;
  
 }

  
 .pagination>.active>a {
  background-color: #1cc88a;
  color: #f8f9fc!important;
 }
  
 .pagination>li>a {
  //border: 1px solid #fff;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  color: #1cc88a!important;
 }
  
 .pagination>li>a, .pagination>li>span {
  color:#1cc88a;
  background-color: #f8f9fc;
  //border-color: #ccc;
  border-radius: 10px;
 }
 .background-custom-card{
    background-color: #f8f9fc;
    width: 100%;
    overflow-x: hidden;
 }
 .background-custom-card-no-overflow{
  background-color: #f8f9fc;
  width: 100%;
}
 .background-row-white{
  background-color: #fff;
 }
 .css-13cymwt-control{
  min-height: 2.5em;
 }
 .basic-multi-select{
  width: 100%;
 }
 .box-ricerca-elenco{
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px
 }
 .title-style-elenco{
    font-weight: bold;
    font-size: large;
    text-align: center;
 }
 .title-campo-style-elenco{
  font-weight: bold;
 }
 .icon-alert{
    color: orange;
    font-size: 4em;
    text-align: center;
 }
 .icon-confirm{
  color: #008000b0;
  font-size: 4em;
  text-align: center;
}
.icon-confirm-small{
  color: #008000b0;
  font-size: 3em;
  text-align: center;
}
 .text-alert-center-bold{
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
 }
 .text-alert-center{
  text-align: center;
  font-size: 1em;
}
.larghezza-100{
  width: 100%;
}
.allinea-testo-sx{
  text-align: left;
}
.link-accedi:hover{
  color: #0f6848
}
.marginb-15{
  margin-bottom: 15px;
}
.curser-pointer{
  cursor: pointer;
}

.react-tags__suggestions {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px; /* Imposta l'altezza massima desiderata */
  overflow-y: auto;
}

.shadow-margin-zero{
  margin-right: 0rem;
  margin-left: 0rem;
}

.font-size-xlg{
  font-size: x-large;
}
.font-size-12{
  font-size: 12px;
}

.icon-green:hover{
  color: #008000b0;
  opacity: 0.7;
}
.align-center{
  text-align: center;
}
.align-right{
  text-align: right;
}
.margin-row{
  margin-right: -0.5rem!important;
  margin-left: -0.5rem!important;
}

.dettaglio nav a {
  color: #008000b0 !important;
  border-bottom: 1px transparent solid!important; 
  /* background-color: #3d63d2 !important; */
  /*border-bottom-color: #008000b0 !important; */
}
// .dettaglio nav a .active {
//   color: #008000b0 !important;
//   background-color: transparent!important;
//   border-bottom-color: #008000b0 !important;
//   border-bottom: 2px solid !important;
  
// }
.display-block{
  display: block;
}

.dettaglio .nav-tabs {
  border-bottom: none!important;
}
.dettaglio .mb-3{
  width: 80%;
}
.altezza-150{
  max-height: 150px;
}
.dimensione_mappa{
  width: 100%;
  height: 300px;
}
.dimensione_mappa_ul{
  width: 100%;
  height: 400px;
}
.text-align-right{
  text-align: right;
}
.float-right{
  float: right;
}
.heigth-max{
  height: 100%!important;
}
.dimensione-chart{
    height: 90%!important;
    width: 90%!important;
    display: block;
    box-sizing: border-box;
}
.margin-left-rigth-15{
  margin-left: 15px;
  margin-right: 15px;
}

.margin-left-7{
  margin-left: 7.5px;
}
.margin-rigth-7{
  margin-right: 7.5px;
}
.margin-bottom-20{
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .margin-left-rigth-15{
    margin-left: -12px;
    margin-right: -12px;
  }
  .margin-left-7{
    // margin-left: 7.5px;
  }
  .margin-rigth-7{
    //margin-right: 7.5px;
  }
  .position-card-center{
    top: 0!important;
  }
}

.position-card-center{
  top: 35%;
  position: relative;
}

.red-icon{
  background-color: red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top:50%
}
.green-icon{
  background-color: green;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top:50%
}
.yellow-icon{
  background-color: yellow;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top:50%
}
.orange-icon{
  background-color: orange;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top:50%
}
.text-deco-none{
  text-decoration: none;
}
.posizioni-lista{
  color: #1cc88a;
  font-weight: bold;
}
.posizioni-lista:hover{
  color: #1cc88a;
  font-weight: bold;
  opacity: 7;
  text-decoration: none!important;
}
.icon-profilo{
  font-size: 30px;
  color: #008000b0;
}
.position-messaggio-errore{
  top: 20%;
  position: relative;
}

.bg-gradient-primary {
  background-color: #1cc88a!important;
  background-image: linear-gradient(180deg, #1cc88a 10%, #33e1a1 100%)!important;
}
.border-left-primary {
  border-left: 0.25rem solid #1cc88a !important;
}
.text-primary {
  color: #5a5c69  !important;
}

.btn-paypal{
  background-color: #1cc88a;
  color: #ffffff;
  padding: 10px;
  border-radius: 0.35rem;
}

.btn-nexi{
  background-color: #36b9cc;
  color: #ffffff;
  padding: 10px;
  border-radius: 0.35rem;
}

.pacchetto-aggiuntivo{
  color: #36b9cc;
  font-weight: bold;
}

.color_cel{
  color: #36b9cc;
}

.btn-paypal:hover{
  background-color: #1cc889c3!important;
  color: #ffffff;
  padding: 10px;
  border-radius: 0.35rem;
  text-decoration: none;
  cursor: pointer;
}

.btn-nexi:hover{
  background-color: #36b8ccb5!important;
  color: #ffffff;
  padding: 10px;
  border-radius: 0.35rem;
  text-decoration: none;
  cursor: pointer;
}
.text-confirm-ordine{
  font-weight: bold;
  font-size: 20px;
}
.color-gray{
  color: #858796;
}
.font-size-large{
  font-size: large;
}
.color-green{
  color: #1cc88a;
}
.alert-dismissible .close:hover{
  color: #806520;
    background-color: #fdf3d8;
    border-color: #fceec9;
}
.font-size-small{
  font-size: small!important;
}
.min-height-250{
  min-height: 250px;
}